<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>

    <v-dialog v-model="dialog_add_edit_exp" width="800">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          <span v-if="dialog_data.id ==  0">เพิ่ม</span>
          <span v-else>แก้ไข</span>ค่าใช้จ่าย
          <v-spacer/>
          <v-btn icon @click="dialog_add_edit_exp=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0 overflow-x-auto" style="height: 500px">
          <v-row class="py-1">
            <v-col cols="6">
              <strong>วันที่</strong> :
            </v-col>
            <v-col cols="3">
              <div class="py-3 px-3" style="border-style: solid; border-color: #767473; border-radius: 5px; border-width: 0.5px;">
                <datetime  type="datetime" format="dd-MM-yyyy HH:mm:ss" v-model="dialog_data.expenseDate"></datetime>
              </div>
            </v-col>
          </v-row>
        <v-row class="py-1">
          <v-col cols="6">
            <strong>รอบบิล</strong> :
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="dialog_reportDate"
                :items="report_date_list"
                :item-text="item => item.description"
                :item-value="item => item.value "
                outlined
                dense
                hide-details
              ></v-select>
          </v-col>
        </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>วันที่จ่ายเงิน</strong> :
            </v-col>
            <v-col cols="3">
               <div class="py-3 px-3" style="border-style: solid; border-color: #767473; border-radius: 5px;; border-width: 0.5px;">
                 <datetime  type="datetime" format="dd-MM-yyyy HH:mm" v-model="dialog_data.bankDate"></datetime>
               </div>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>จากบัญชี</strong> :
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="dialog_data.bankID"
                ref="bankID"
                :items="branch_bankAccount_list_for_dialog"
                :item-text="item => item.fullBankName"
                :item-value="item => item.id "
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ประเภท</strong> :
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="dialog_data.branchExpenseTypeID"
                ref="branchExpenseTypeID"
                :items="finance_type_list_for_dialog"
                :item-text="item => item.description"
                :item-value="item => item.id "
                outlined
                dense
                hide-details
              ></v-autocomplete>
              <v-btn class="mt-2" v-show="type_add == false" @click="type_add = true, type_add_text = null " color="success">เพิ่มประเภท</v-btn>
              <v-btn class="mt-2" v-show="type_add == true" @click="Add_type()" color="info">บันทึก</v-btn>
              <v-btn class="mt-2 mx-1" v-show="type_add == true" @click="type_add = false , type_add_text = null" color="warning">ยกเลิก</v-btn>
              <div v-if="type_add" style="border-style: dotted; border-color : #767473 ; " class="pa-3 my-2">
                <span><b>กรุณากรอก ประเภท ที่ต้องการเพิ่ม</b> </span>
                <v-text-field
                  v-model="type_add_text"
                  ref="type_add_text"
                  autocomplete="off"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <span style="color: red;" v-if="add_type_status == 'duplicate'"> มีประเภทนี้อยู่แล้ว !! </span>
              </div>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ยอดก่อน VAT</strong> :
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="dialog_data.amount"
                ref="amount"
                autocomplete="off"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ยอด VAT</strong> :
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="dialog_data.vat"
                ref="vat"
                autocomplete="off"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-3">
            <v-col cols="6">
              <strong>ยอดรวม</strong> :
            </v-col>
            <v-col cols="6">
              <span style="color: red"> <b>{{ formatMoney(Number(dialog_data.amount) + Number(dialog_data.vat)) }}</b></span>  <b>บาท</b>

            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ประเภทค่าใช้จ่าย</strong> :
            </v-col>
            <v-col cols="6">
             <v-radio-group
               v-model="dialog_data.isExpense"
               row
             >
               <v-radio
                 label="เป็นค่าใช้จ่ายได้"
                 :value="true"
               ></v-radio>
               <v-radio
                 label="ไม่ถือเป็นค่าใช้จ่าย"
                 :value="false"
               ></v-radio>
             </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>TAX ID ของผู้ให้บริการ</strong> :
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="dialog_data.companyTaxID"
                ref="companyTaxID"
                autocomplete="off"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ชื่อผู้ให้บริการ</strong> :
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="dialog_data.companyName"
                ref="companyName"
                autocomplete="off"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>เลขที่บิล</strong> :
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="dialog_data.invoiceNo"
                ref="invoiceNo"
                autocomplete="off"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>รายละเอียด</strong> :
            </v-col>
            <v-col cols="6">
              <v-textarea
                 v-model="dialog_data.remark"
                 filled
               ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>แนบไฟล์</strong> :
            </v-col>
            <v-col cols="6">
              <!-- ดูตัวอย่างหน้าตั้งค่า -->
              <v-avatar
                v-if="dialog_data.photoLink!=null && input_chanalFileUpload == null"
                class="sshoverpicture" size="150" tile >
                <img :src="dialog_data.photoLink" />
              </v-avatar>

              <v-file-input
                v-model="input_chanalFileUpload"
                ref="input_chanalFileUpload"
                label="เลือกไฟล์"
                class="mt-5"
                outlined
              ></v-file-input>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>การคำนวนกำไร</strong> :
            </v-col>
            <v-col cols="6">
              <v-radio-group
                v-model="dialog_data.isCalculateInProfit"
                column
              >
                <v-radio
                  label="คำนวนในกำไร"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="ไม่ต้องคำนวนในกำไร"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="mx-2">
          <v-spacer/>
          <v-btn dark color="primary" class="my-1" @click="AddUpdateExpense()"><v-icon class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>
          <!-- <v-spacer/> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading :loading="loading" />
    <!-- <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1" v-if="navigete!=null && navigete!='' && navigete!='null'">
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        {{ navigete[0]['title'] }}
      </v-card-title>
    </v-card> -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        ค่าใช้จ่าย
      </v-card-title>
    </v-card>


    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <div>
          <v-row>
            <v-col cols="12" md="2" lg="1" class=" pb-2">
              <span><b> เลือกวันที่ : </b></span>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="  pb-2 ">
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    :value="startDate"
                    @change="value => startDate = value"
                    autocomplete="off"
                    label="วันที่เริ่มต้น"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12"   md="3" lg="2" class="  pb-2 ">
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    :value="endDate"
                    @change="value => endDate = value"
                    autocomplete="off"
                    label="วันที่สิ้นสุด"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details

                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedDates">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2 ">
              <span><b> ประเภท : </b></span>
            </v-col>
            <v-col cols="12" md="6" lg="4"  class="pb-2" style="display: flex;">
              <v-autocomplete
                v-model="finance_type_select"
                :items="finance_type_list"
                :item-text="item => item.description"
                :item-value="item => item.id "
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2 ">
              <span><b> สร้างโดย : </b></span>
            </v-col>
            <v-col cols="12" md="6" lg="4"  class="pb-2" style="display: flex;">
              <v-autocomplete
                v-model="search_CreatedBy"
                :items="staff_branch_list"
                :item-text="item => item.name"
                :item-value="item => item.id "
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2 ">
            </v-col>
            <v-col cols="12"  md="6" lg="4"   class="pb-2">
              <v-btn dark color="error" @click="get_branch_expense_list()" class="fn-14"><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
          <v-icon size="20" color="success">fa-receipt</v-icon><span class="my-3 ml-3">รายการค่าใช้จ่าย</span>
          <v-spacer/>
          <v-btn color="success" @click="open_dialog('add', null)"><v-icon left>fa-plus</v-icon>เพิ่ม</v-btn>
          <!-- <v-btn v-if="shop.selected_to_make_invoice.length>0" class="ma-1" color="primary" @click="confirmCreateInvoice()">สร้างใบแจ้งหนี้</v-btn>
          <v-btn v-show="invoice.table.length != 0" size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
            <vue-excel-xlsx
                :data="invoice.table"
                :columns="excel_invoice_list_header"
                :filename="'รายงานใบแจ้งหนี้'"
                :sheetname="'รายงานใบแจ้งหนี้'"
                >
                Export to Excel
            </vue-excel-xlsx>
          </v-btn> -->
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="mt-2 mx-0">
          <v-col cols="12" class=" px-0">
            <!-- PC -->
            <div class="overflow-x-auto">
              <v-data-table
                :headers="headers_expenses"
                :items="data_expenses_list"
                item-key="id"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="100000"
                :mobile-breakpoint="0"
                >
                <template v-slot:item.edit="{ item }">
                  <v-btn icon><v-icon size="18" color="info" @click="open_dialog('edit', item)">fa-edit</v-icon></v-btn>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn icon><v-icon size="18" color="red" @click="delete_alert(item)">fa-trash-alt</v-icon></v-btn>
                </template>
                <template v-slot:item.amount="{ item }">
                  {{ formatMoney( item.amount) }}
                </template>
                <template v-slot:item.vat="{ item }">
                  <span v-if="item.vat > 0 && item.vat != null ">{{ formatMoney( item.vat) }}</span>
                </template>
                <template v-slot:item.amount_sum_vat="{ item }">
                  {{ formatMoney( item.amount + item.vat) }}
                </template>
              </v-data-table>
            </div>


          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { Datetime } from 'vue-datetime';
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { branchService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot, set_format_date_time, format_price, format_vat_type } from '@/website/global_function'
  export default {
    components: {
      Loading,
      datetime: Datetime
    },
    data: () => ({
      page_loading: true,
      loading: false,

      navigete: [],

      // เปิด เลือกวันที่
      startDate: null,
      menuStartDate: false,
      checkStart: true,

      endDate: null,
      menuEndDate: false,
      checkEnd: true,
      // ปิด เลือกวันที่

      search_CreatedBy: null,
      staff_branch_list: [],

      finance_type_select : null,
      finance_type_list : [],

      headers_expenses : [

        { text: 'แก้ไข', value: 'edit', sortable: false, align: 'center', width: '50px' },
        { text: 'ลบ', value: 'delete', sortable: false, align: 'center', width: '50px' },
        { text: 'วันที่', value: 'expenseDate', sortable: false, align: 'center', width: '120px' },
        { text: 'รอบภาษี', value: 'reportDate', sortable: false, align: 'center', width: '120px' },
        { text: 'ประเภทค่าใช้จ่าย', value: 'branchExpenseTypeName', sortable: false, align: 'left', width: '150px' },
        { text: 'หมายเหตุ', value: 'remark', sortable: false, align: 'left', width: '200px' },
        { text: 'ยอดเงินก่อน VAT', value: 'amount', sortable: false, align: 'right', width: '150px' },
        { text: 'VAT', value: 'vat', sortable: false, align: 'right', width: '120px' },
        { text: 'ยอดรวม', value: 'amount_sum_vat', sortable: false, align: 'right', width: '120px' },
        { text: 'ชื่อบริษัท', value: 'companyName', sortable: false, width: '200px'},
        { text: 'เลขผู้เสียภาษี', value: 'companyTaxID', sortable: false, width: '120px'},
        { text: 'เลขบิล', value: 'invoiceNo', sortable: false, width: '120px'},
        { text: 'สร้างวันที่', value: 'created', sortable: false, align: 'center', width: '120px' },
        { text: 'สร้างโดย', value: 'createdByName', sortable: false, align: 'center', width: '120px' },
      ],
      data_expenses_list : [],

      dialog_add_edit_exp : false,
      branch_bankAccount_list_for_dialog: [],
      finance_type_list_for_dialog : [],
      input_chanalFileUpload : null,
      dialog_data : {
        "id": 0,
        "branchID": null,
        "createdBy": null,
        "expenseDate": null,
        "reportDate": null,
        "bankDate": null,
        "bankID": null,
        "branchExpenseTypeID": null,
        "amount": 0,
        "vat": 0,
        "isExpense": true,
        "companyTaxID": null,
        "companyName": null,
        "invoiceNo": null,
        "remark": null,
        "photoLink": null,
        "isCalculateInProfit": true
      },
      dialog_action : null,
      dialog_reportDate: null,
      report_date_list : [
        {"value": 1 , "description": "01-yyyy"},
        {"value": 2 , "description": "02-yyyy"},
        {"value": 3 , "description": "03-yyyy"},
        {"value": 4 , "description": "04-yyyy"},
        {"value": 5 , "description": "05-yyyy"},
        {"value": 6 , "description": "06-yyyy"},
        {"value": 7 , "description": "07-yyyy"},
        {"value": 8 , "description": "08-yyyy"},
        {"value": 9 , "description": "09-yyyy"},
        {"value": 10 , "description": "10-yyyy"},
        {"value": 11 , "description": "11-yyyy"},
        {"value": 12 , "description": "12-yyyy"}
      ],

      type_add : false,
      type_add_text : null,
      add_type_status : null,

    }),
    async created() {
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      await this.get_branch_expense_type()
      await this.get_staff_branch_expense_list()
      await this.get_branch_expense_list()
      this.page_loading = false
      this.get_branch_bank_account_list()

    },
    computed: {
    },
    watch: {
      startDate: async function(newVal, oldVal) {
        if(newVal != null && oldVal != null ){
          try {
            this.$refs.menuStartDate.save(this.startDate)
          }catch(err){
            console.log(err);
          }
        }

      },
      endDate: async function(newVal, oldVal) {
        if(newVal != null && oldVal != null ){
          try {
            this.$refs.menuEndDate.save(this.endDate)
          }catch(err){
            console.log(err);
          }
        }

      },

      selectInvoiceDate: async function(newVal, oldVal) {
        if(newVal != null){
          if(oldVal != null){
            this.$refs.menuSelectInvoiceDate.save(this.selectInvoiceDate)
          }
        }
      },

      dialog_reportDate : async function(newVal, oldVal) {
        if(newVal != null && newVal != ''){
          const d = new Date();
          // let month = (d.getMonth() + 1);
          let year = d.getFullYear().toString();
          var month_tmp = ""
          if(newVal < 10){
            month_tmp =  "0" + newVal.toString()
          }else {
            month_tmp = newVal.toString()
          }
          this.dialog_data.reportDate = year + '-' + month_tmp + "-01 00:00:00"

        }
      },

      type_add_text : async function(newVal, oldVal){
        this.add_type_status = null
      },

    },
    methods: {

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },
      async get_branch_expense_type () {
        try {
          let response = await axios.post(branchService_dotnet+'Branch/get-branch-expense-type', {
            "branchId": localStorage.getItem('Branch_BranchID'),
          },{ headers: header_token})
          this.finance_type_list = response.data
          var type_tmp1 = {"id": null, "branchID": null, "description": "-- ค่าใช้จ่ายทั้งหมด --"}
          this.finance_type_list.unshift(type_tmp1)

          this.finance_type_list_for_dialog = [...response.data]
          var type_tmp2 = {"id": null, "branchID": null, "description": "-- กรุณาเลือก --"}
          this.finance_type_list_for_dialog.unshift(type_tmp2)
        } catch (e) {
          this.$swal({
            type: 'warning',
            title: 'get-branch-expense-type เกิดข้อผิดพลาดบางอย่าง!!',
            text: e.message,
          })
        }
      },

      async get_branch_expense_list () {
        try {
          this.loading = true
          let response = await axios.post(branchService_dotnet+'Branch/get-branch-expense-list', {
            "branchId": localStorage.getItem('Branch_BranchID'),
            "branchExpenseTypesID": this.finance_type_select,
            "createdByID": this.search_CreatedBy,
            "dateFrom": this.startDate,
            "dateTo": this.endDate
          },{ headers: header_token})
          this.loading = false
          this.data_expenses_list = response.data.data
          this.startDate = response.data.dateFrom
          this.endDate = response.data.dateTo
        } catch (e) {
          this.loading = false
          this.$swal({
            type: 'warning',
            title: 'get-branch-expense-list เกิดข้อผิดพลาดบางอย่าง!!',
            text: e.message,
          })
        }

      },

      async get_staff_branch_expense_list () {
        try {

          let response = await axios.post(branchService_dotnet+'Branch/get-staff-branch-expense-list', {
            "branchId": localStorage.getItem('Branch_BranchID'),
          },{ headers: header_token})
          this.staff_branch_list = response.data

          var type_tmp = {"id": null,  "name": "-- ทั้งหมด --"}
          this.staff_branch_list.unshift(type_tmp)

        } catch (e) {
          this.$swal({
            type: 'warning',
            title: 'get-staff-branch-expense-list เกิดข้อผิดพลาดบางอย่าง!!',
            text: e.message,
          })
        }

      },

      async get_branch_bank_account_list () {
        try {

          let response = await axios.post(branchService_dotnet+'Branch/get-branch-bank-account-list', {
            "branchId": localStorage.getItem('Branch_BranchID'),
          },{ headers: header_token})
          this.branch_bankAccount_list_for_dialog = response.data

          for (var item of this.branch_bankAccount_list_for_dialog) {
            if(item.accountNumber != null && item.accountNumber != '' ){
              item.fullBankName = item.bankName + ' : ' + item.accountNumber + ' : ' + item.accountName
            } else {
              item.fullBankName = item.bankName
            }

          }
          var type_tmp = {"id": null,  "fullBankName": " -- กรุณาเลือก -- "}
          this.branch_bankAccount_list_for_dialog.unshift(type_tmp)

        } catch (e) {
          this.$swal({
            type: 'warning',
            title: 'get-branch-bank-account-list เกิดข้อผิดพลาดบางอย่าง!!',
            text: e.message,
          })
        }

      },

      open_dialog (act, item) {
        this.dialog_action = act
        this.input_chanalFileUpload = null
        const d = new Date();
        let month = (d.getMonth() + 1);
        let year = d.getFullYear().toString();

        for (var set_year of this.report_date_list) {
          set_year.description = set_year.description.replace("yyyy", year)
        }

        if(act == 'add'){
          this.dialog_data = {
            "id": 0,
            "branchID": Number(localStorage.getItem('Branch_BranchID')),
            "createdBy": Number(localStorage.getItem('Branch_StaffID')),
            "expenseDate": (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            "reportDate": null,
            "bankDate": (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            "bankID": null,
            "branchExpenseTypeID": null,
            "amount": 0,
            "vat": 0,
            "isExpense": true,
            "companyTaxID": null,
            "companyName": null,
            "invoiceNo": null,
            "remark": null,
            "photoLink": null,
            "isCalculateInProfit": true
          }
          this.dialog_reportDate = month

        } else {
          this.dialog_data = {
            "id": item.id,
            "branchID": Number(localStorage.getItem('Branch_BranchID')),
            "createdBy": Number(localStorage.getItem('Branch_StaffID')),
            "expenseDate": item.expenseDate_Origin,
            "reportDate": item.reportDate_Origin,
            "bankDate": item.bankDate_Origin,
            "bankID": item.bankID,
            "branchExpenseTypeID": item.branchExpenseTypeId,
            "amount": item.amount,
            "vat": item.vat,
            "isExpense": item.isExpense,
            "companyTaxID": item.companyTaxID,
            "companyName": item.companyName,
            "invoiceNo": item.invoiceNo,
            "remark": item.remark,
            "photoLink": item.photoLink,
            "isCalculateInProfit": item.isCalculateInProfit,
          }

          const myArrayDate = item.reportDate.split("-");
          this.dialog_reportDate = Number(myArrayDate[1])
        }
        this.type_add = false
        this.type_add_text = null
        this.dialog_add_edit_exp = true
      },

      async AddUpdateExpense () {

        try {
          if(this.dialog_data.bankID == null){
            this.$refs.bankID.focus()
            return
          } else if (this.dialog_data.branchExpenseTypeID == null) {
            this.$refs.branchExpenseTypeID.focus()
            return
          } else if (this.dialog_data.companyTaxID == null || this.dialog_data.companyTaxID == '') {
            this.$refs.companyTaxID.focus()
            return
          } else if (this.dialog_data.companyName == null || this.dialog_data.companyName == '') {
            this.$refs.companyName.focus()
            return
          } else if (this.dialog_data.invoiceNo == null || this.dialog_data.invoiceNo == '') {
            this.$refs.invoiceNo.focus()
            return
          }

          var data_for_upload = await this.ImageAction(this.input_chanalFileUpload) ;

          this.loading = true
          let response = await axios.post(branchService_dotnet+'Branch/update-branch-expense', {
            "id": this.dialog_data.id,
            "branchID": this.dialog_data.branchID,
            "createdBy": this.dialog_data.createdBy,
            "expenseDate": this.dialog_data.expenseDate,
            "reportDate": this.dialog_data.reportDate,
            "bankDate": this.dialog_data.bankDate,
            "bankID": this.dialog_data.bankID,
            "branchExpenseTypeID": this.dialog_data.branchExpenseTypeID,
            "amount": this.dialog_data.amount,
            "vat": this.dialog_data.vat,
            "isExpense": this.dialog_data.isExpense,
            "companyTaxID": this.dialog_data.companyTaxID,
            "companyName": this.dialog_data.companyName,
            "invoiceNo": this.dialog_data.invoiceNo,
            "remark": this.dialog_data.remark,
            "photoLink": data_for_upload,
            "isCalculateInProfit": this.dialog_data.isCalculateInProfit
          },{ headers: header_token})
          this.dialog_add_edit_exp = false
          await this.get_branch_expense_list()
          this.loading = false
        } catch (e) {
          this.loading = false
          this.$swal({
            type: 'warning',
            title: 'update-branch-expense เกิดข้อผิดพลาดบางอย่าง!!',
            text: e.message,
          })
        }

      },

      async ImageAction (dataImg) {
        var fileToBase64_tmp = null
        if (dataImg != null) {
          var fileToBase64 = null
          const toBase64 = file => new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = () => resolve(reader.result)
              reader.onerror = error => reject(error)
          })

          fileToBase64 = await toBase64(dataImg)
          fileToBase64_tmp = fileToBase64
          return  fileToBase64_tmp ;
        } else {
          return null ;
        }

      },


      async Add_type () {

        try {
          if(this.type_add_text == null || this.type_add_text == ''){
            this.$refs.type_add_text.focus()
            return
          }
          this.loading = true
          let response = await axios.post(branchService_dotnet+'Branch/add-expense-type', {
            "branchID": localStorage.getItem('Branch_BranchID'),
            "expenseName": this.type_add_text
          },{ headers: header_token})
          this.add_type_status = response.data
          // duplicate / true / false
          this.get_branch_expense_type()
          this.loading = false
          if(response.data == 'duplicate'){
            return
          }
          this.type_add = false
        } catch (e) {
          this.loading = false
          this.$swal({
            type: 'warning',
            title: 'add-expense-type เกิดข้อผิดพลาดบางอย่าง!!',
            text: e.message,
          })
          this.$refs.type_add_text.focus()
          return
        }
      },

      delete_alert (item) {
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบรายการค่าใช้จ่ายนี้ !!',
          text: 'รายการ ' + item.invoiceNo + ' จะถูกลบออก !!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.deleteExpense(item.id)
          }
        })
      },

      async deleteExpense (expense_id) {
        try {
          this.loading = false
          let response = await axios.post(branchService_dotnet+'Branch/delete-branch-expense', {
            "branchExpenseID":  expense_id
          },{ headers: header_token})
          this.loading = false
          this.get_branch_expense_list()
        } catch (e) {
          this.loading = false
          this.$swal({
            type: 'warning',
            title: 'delete-branch-expense เกิดข้อผิดพลาดบางอย่าง!!',
            text: e.message,
          })
        }
      },

      FormatInvoice,
      FormatOrder,
      formatMoney,
      isNumberWNoDot,
      set_format_date_time,
      format_price,
      format_vat_type,
      AlertSuccess,
      AlertWarning,
      AlertError,
    }
  }
</script>

<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
      display: none;
  }

  .mobile-none {
    display: ;
  }

  .pc-none {
    display: none;
  }

  @media (max-width: 599px) {
    .mobile-none {
      display: none;
    }

    .pc-none {
      display: ;
    }
  }
</style>
